<template>
  <div>
    <!-- details role -->
    <b-card class="invoice-preview-card">
      <div class="d-flex justify-content-between flex-wrap">
        <h2 class="my-auto">
          {{ $t('roles.detail_title') }}  {{ detailsTitle }}<br>
        </h2>
        <b-button
          variant="outline-primary"
          class="d-flex align-items-center"
          @click="$router.push({ name: 'config-roles' })"
        >
          <i class="las la-arrow-left mr-50" />
          <span>{{ $t('button.back_to_list') }}</span>
        </b-button>
      </div>
    </b-card>
    <!-- filtre -->
    <!--    <b-card>
      <b-row class="px-1 d-flex align-items-center nopadding">
        <b-col cols="9" class="nopadding">
          <h2>{{ form.title }}</h2>
        </b-col>
      </b-row>
      <hr class="py-1" />
      <b-row class="px-3 nopadding">
        <span v-html="form.description"> </span>
      </b-row>
    </b-card> -->
    <!-- permissions -->
    <b-card class="mt-1">
      <b-card-header>
        <h3>{{ $t('roles.detail_permission_title') }}</h3>
      </b-card-header>
      <b-form @submit.prevent>
        <b-row class="px-1">
          <b-col cols="6">
            <b-form-group
              :label="$t('roles.filter_label_resource')"
              label-for="resource"
              label-cols-md="12"
            >
              <b-form-input
                id="module"
                v-model="filters.module"
                :placeholder="$t('roles.filter_input_resource_placeholder')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="$t('roles.filter_label_action')"
              label-cols-md="12"
              label-for="permission"
            >
              <b-form-input
                id="permission"
                v-model="filters.permission"
                :placeholder="$t('roles.filter_input_action_placeholder')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mb-2 pr-2"
          >
            <b-button
              class="float-right"
              variant="warning"
              @click="searchRolePermissions()"
            >
              <feather-icon
                v-if="!isSearchingPermission"
                icon="SearchIcon"
                class="mr-25"
              />
              <b-spinner
                v-else
                small
                class="mr-25"
              />
              <span>{{ $t('button.search') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>

      <b-table
        responsive
        :items="permissionsList"
        :fields="tableColumns"
        :busy="loadingData"
        show-empty
        :empty-text="$t('table_columns.empty_text')"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #cell(id)="data">
          <strong>{{ data.index + 1 }}</strong>
        </template>
        <template #cell(action)="data">
          <b-form-checkbox
            v-model="data.item.pivot.is_active"
            class="custom-control-success"
            name="check-button"
            switch
            @change="updatePermissionStatus(data.item.id, data.item.pivot.is_active)"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </template>
      </b-table>
      <PaginationComponent
        :table-data="permissionsList"
        :per-page="paginationData.perPage"
        :total-rows="paginationData.total"
        @change="onPaginationChange"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTags,
  BSpinner,
  BBadge,
  BCardHeader,
  BTable,
  BForm,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueEditor } from 'vue2-editor'

import { mapActions } from 'vuex'
import PaginationComponent from '@/components/PaginationComponent.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import rolesStoreModule from '@/store/roles'
import permissionsStoreModule from '@/store/permissions'

import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BForm,
    BFormInput,
    BFormTags,
    VueEditor,
    vSelect,
    BTable,
    BSpinner,
    BBadge,
    BCardHeader,
    PaginationComponent,
    BFormCheckbox,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'roles', module: rolesStoreModule },
      { path: 'permissions', module: permissionsStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    return {
      requiredStoreModules,
    }
  },

  data() {
    return {
      resource: '',
      action: '',
      currentId: null,
      form: {},
      detailsTitle: '',
      loadingData: false,
      isSearchingPermission: false,
      isUpdatingPermissionStatus: false,
      filters: {
        resource: '',
        permission: '',
        module: '',
      },
      permissionsList: [],
      tableColumns: [
        { key: 'module', label: 'Module', sortable: true },

        {
          key: 'description',
          label: 'Permission',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Actions',
          sortable: true,
        },
      ],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200, 500],
    }
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        this.detailsTitle = val.params.title
        await this.searchRolePermissions()
      },
    },
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.permissionsList && this.permissionsList[val - 1]) {
          this.permissionsList = this.customCloneDeep(
            this.permissionsList.slice(0, val - 1),
          )
        }
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.searchRolePermissions()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        this.searchRolePermissions()
      },
    },
  },
  methods: {
    ...mapActions('permissions', {
      action_searchPermissions: 'searchPermissions',
      action_updatePermissionStatus: 'updatePermissionStatus',
    }),
    ...mapActions('roles', {
      action_getRoles: 'getRoles',
      action_searchRolePermissions: 'searchRolePermissions',
    }),
    searchRolePermissions() {
      this.isSearchingPermission = true
      const { id } = this.$route.params
      const params = {
        module: this.filters.module,
        description: this.filters.permission,
        // params for filter by pagination
        page: this.paginationData.currentPage,
        per_page: this.paginationData.perPage,
      }
      console.log("params:::" , params)
      this.action_searchRolePermissions({
        id,
        data: params,
      })
        .then(response => {
          console.log('response.data.data:', response.data.data[0])
          this.permissionsList = response.data.data
          this.isSearchingPermission = false
          console.log('permissions:::', this.permissionsList)
          // --------------------------------------------------------------
          this.paginationData.total = response.data.meta.total || 0
          if (this.paginationData.metaData.from === 0) {
            this.paginationData.metaData.from += 1
          } else {
            this.paginationData.metaData.from = this.paginationData.perPage * this.paginationData.currentPage
              - this.paginationData.perPage
          }
          this.paginationData.metaData.to = this.paginationData.perPage * this.paginationData.currentPage
            - this.paginationData.perPage
            + response.data.meta.total
          // --------------------------------------------------------------
        })
        .catch(error => {
          this.isSearchingPermission = false
          console.log(error)
        })
    },
    updatePermissionStatus(id, status) {
      this.isUpdatingPermissionStatus = true
      this.action_updatePermissionStatus({
        role_id: parseInt(this.$route.params.id),
        is_active: status,
        permission_ids: [id],
      })
        .then(response => {
          this.isUpdatingPermissionStatus = false
          this.searchRolePermissions()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: status === true
                ? 'La permission est activée'
                : 'La permission est désactivée',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.isSearchingPermission = false
          console.log(error)
        })
    },
    onPaginationChange(val) {
      this.paginationData.currentPage = val.currentPage
      this.paginationData.perPage = val.perPage
    },
  },
}
</script>
<style lang="scss" scoped>
.invoice-preview-wrapper {
  .row.invoice-preview {
    .col-md-8 {
      max-width: 100%;
      flex-grow: 1;
    }

    .invoice-preview-card {
      .card-body:nth-of-type(2) {
        .row {
          >.col-12 {
            max-width: 50% !important;
          }

          .col-12:nth-child(2) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 0 !important;
          }
        }
      }
    }
  }

  // Action Right Col
  .invoice-actions {
    display: none;
  }
}
</style>
